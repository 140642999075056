import React from 'react'

export default function PrestigeCard(props) {
    if(props.offset === true){
        return (
          <div className='w-[21.4375rem] h-[30.5rem] lg:w-[22.625rem] lg:h-[36.875rem] relative mt-4'>
            <div id='border' className='w-[15.9375rem] h-[18.5rem] lg:w-[19.375rem] lg:h-[23rem] bg-pink-red  bottom-0 left-0 z-0 absolute'>
            </div>
            <img src={props.img} alt='hand with bp card' className='w-[20.9375rem] h-[30rem] z-1  left-[0.5rem] bottom-[0.5rem] absolute lg:w-[25.625rem] lg:h-[34.875rem]'>
            </img>
            <div className='bg-img-mask width w-[20.9375rem] h-[30rem] z-2  bg-opacity-50 left-[0.5rem] flex flex-col items-baseline absolute lg:w-[22.625rem] lg:h-[34.875rem] lg:bottom-2'>
                <img src='logo.svg' className='w-[3.1875rem] h-[2.5rem] left-[1rem] top-[1rem] absolute z-3'></img>
                <p className='w-[13.5rem] h-[2rem] text-left text-white font-Poppins text-[2rem] font-semibold leading-none absolute top-[6.75rem] left-[2rem] lg:left-[5rem] lg:top-[12.75rem] z-4'>{props.title}</p>
                <p className='w-[13.5rem] h-[3rem] absolute top-[9rem] left-[2rem] font-Poppins font-extralight text-[1rem] text-white text-left lg:left-[5rem] lg:top-[14.75rem]'>{props.subtitle}</p>
                <p className='absolute w-[14.1875rem] h-[1.75rem] text-left  text-white font-DMSans text-base left-[3.5rem] lg:left-[6.5rem] top-[16rem] lg:top-[20.75rem]'>• {props.lign1}</p>
                <p className='absolute w-[14.1875rem] h-[1.75rem] text-left  text-white font-DMSans text-base left-[3.5rem] top-[20rem] lg:left-[6.5rem] lg:top-[24.75rem]'>• {props.lign2}</p>
                <p className='absolute w-[14.1875rem] h-[1.75rem] text-left  text-white font-DMSans text-base left-[3.5rem] top-[24rem] lg:top-[28.75rem] lg:left-[6.5rem]'>• {props.lign3}</p>
                <a className='absolute left-[3.5rem] bottom-[2.25rem] text-white font-DMSans font-medium underline lg:left-[6.5rem] lg:top-[29.75rem] hidden' href={props.link}>En Savoir Plus</a>
            </div>
          </div>
        )
    }else{
        return(
            <div className='w-[21.4375rem] h-[30.5rem] relative mt-4  lg:w-[22.825rem] lg:h-[36.875rem] lg:ml-6'>
            <img src={props.img} alt='hand with bp card' className='w-[20.9375rem] h-[30rem] z-1  left-[0.5rem] bottom-[0.5rem] absolute lg:w-[25.625rem] lg:h-[34.875rem]'>
            </img>
            <div className='bg-img-mask width w-[20.9375rem] h-[30rem] z-2  bg-opacity-50 left-[0.5rem] flex flex-col items-baseline absolute lg:w-[22.825rem] lg:h-[34.875rem] lg:bottom-2'>
                <img src='logo.svg' className='w-[3.1875rem] h-[2.5rem] left-[1rem] top-[1rem] absolute z-3'></img>
                <p className='w-[13.5rem] h-[2rem] text-left text-white font-Poppins text-[2rem] font-semibold leading-none absolute top-[6.75rem] left-[2rem] lg:left-[5rem] lg:top-[12.75rem] z-4'>{props.title}</p>
                <p className='w-[13.5rem] h-[3rem] absolute top-[9rem] left-[2rem] font-Poppins font-extralight text-[1rem] text-white text-left lg:left-[5rem] lg:top-[14.75rem] lg:w-[17rem]'>{props.subtitle}</p>
                <p className='absolute w-[14.1875rem] h-[1.75rem] text-left  text-white font-DMSans text-base left-[3.5rem] lg:left-[6.5rem] top-[16rem] lg:top-[20.5rem]'>• {props.lign1}</p>
                <p className='absolute w-[14.1875rem] h-[1.75rem] text-left  text-white font-DMSans text-base left-[3.5rem] top-[20rem] lg:left-[6.5rem] lg:top-[24.5rem]'>• {props.lign2}</p>
                <p className='absolute w-[14.1875rem] h-[1.75rem] text-left  text-white font-DMSans text-base left-[3.5rem] top-[24rem] lg:top-[28.5rem] lg:left-[6.5rem]'>• {props.lign3}</p>
                <a className='hidden absolute left-[3.5rem] bottom-[2.25rem] text-white font-DMSans font-medium underline lg:left-[6.5rem] lg:top-[29.75rem]' href={props.link}>En Savoir Plus</a>
            </div>
          </div>
        )
    }
}

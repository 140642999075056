import React,{useState} from 'react'
import { Link } from 'react-router-dom'
export default function CommingSoon(props) {
    if(props.popup == true){
        return (
          <div id='popup' className='fixed top-0 left-0 w-full h-full z-50 bg-[#565656] flex justify-center items-center'>
            <div className='mt-16 w-[20.9375rem] h-[32rem] lg:w-[66.375rem] lg:h-[35.75rem] bg-slate-50 opacity-100 relative flex flex-row'>
              <img onClick={props.toggle} src="croix.svg" alt='close' className='absolute w-6 lg:w-8 lg:h-8 top-4 right-4 cursor-pointer'/>
              <img className='hidden lg:flex' src='boat.png' alt='boat'/>
              <div className='flex flex-col mt-16 ml-8'>
                <h1 className='w-[19rem] h-[6.75rem] text-[1.5rem] font-Poppins lg:text-[2.25rem] font-bold text-left pr-4 lg:pr-0 lg:w-[25.312rem] lg:h-auto text-black'>
                Retrouvez votre compte <span className='text-pink-red lg:mr-0'>B PARTNER</span> Bientôt en ligne.
                </h1>
                <p className='font-Poppins text-left w-[18.75rem] text-[#98949E] mt-6 lg:w-[31rem] pr-2'>
                Votre accès à votre compte B Partner sera bientôt disponible, n’hésitez pas à nous contacter pour plus d’informations.
                </p>
                <p className='font-Poppins text-left text-[#98949E] mt-8'>
                L’équipe B Partner.
                </p>
                <Link className='w-fit h-fit' to='/contact'><button className='w-[12.125rem] h-[3rem] bg-pink-red text-white text-center font-Poppins rounded font-medium mt-12'>
                  Nous contacter
                </button>
                </Link>
              </div>
            </div>
          </div>
        )
    }else{
        <></>
    }
}

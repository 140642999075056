import React, {useState} from 'react'
import useScreenWidth from '../hooks/UseScreenWidth'
import CommingSoon from './CommingSoon';

export default function CardAdvantage(props) {
  const [popup, setPopup] = useState(false);

  const toggle = ()=>{
      setPopup(!popup);
  }
  const screenWidth = useScreenWidth();
  if(screenWidth < 768){
    return(
      <div className='flex flex-col mt-14 md:flex-row justify-between'>
        <CommingSoon popup={popup} toggle={toggle}/>
          <div className='w-[21.9375rem] h-[13.5rem] relative'>
              <div className='bg-pink-red h-full w-1/2 absolute right-0'></div>
              <img className='w-[20.4375rem] h-[12.5rem] z-1 absolute top-2 right-2' src={props.img}></img>
          </div>
          <div className='ml-2 mt-6 md:w-[18.875rem]'>
            <p className='text-left font-Poppins text-grey-text font-bold uppercase'>{props.title}</p>
            <p className='text-left mt-2 font-DMSans tracking-wider text-[#5C6F7C] leading-relaxed'>{props.text}</p>
            <p onClick={toggle} className='uppercase flex justify-start items-start text-xs font-DMSans mt-2'><div className='my-auto w-[2rem] border-pink-red border mr-2'></div>En savoir plus</p>
          </div>
      </div>
    )
  }else{
    if(props.PicPosition === 'left'){
      return (
        <div className='flex flex-col mt-14 lg:mt-20 md:flex-row justify-around'>
          <CommingSoon popup={popup} toggle={toggle}/>
          <div className='w-[21.9375rem] h-[13.5rem] relative lg:w-[33.4375rem] lg:h-[22rem]'>
              <div className='bg-pink-red h-full w-1/2 absolute right-0'></div>
              <img className='w-[20.4375rem] h-[12.5rem] z-1 absolute top-2 right-2 lg:w-[32rem] lg:h-[20.9rem]' src={props.img}></img>
          </div>
          <div className='ml-2 mt-6 md:w-[18.875rem] lg:flex lg:flex-col lg:justify-center'>
            <p className='text-left font-Poppins text-grey-text font-bold uppercase'>{props.title}</p>
            <p className='text-left mt-4 font-DMSans tracking-[0.075rem] text-[#5C6F7C] leading-relaxed'>{props.text}</p>
            <p onClick={toggle} className='uppercase flex justify-start items-start text-xs font-DMSans mt-4 cursor-pointer'><div className='my-auto w-[2rem] border-pink-red border mr-2'></div>En savoir plus</p>
          </div>
        </div>
      )
    }else{
      return(
        <div className='flex flex-col mt-14 md:flex-row lg:mt-20 justify-around'>
        <CommingSoon popup={popup} toggle={toggle}/>
        <div className='ml-2 mt-6 md:w-[18.875rem] lg:flex lg:flex-col lg:justify-center'>
          <p className='text-left font-Poppins text-grey-text font-bold uppercase'>{props.title}</p>
          <p className='text-left mt-4 font-DMSans tracking-[0.075rem] text-[#5C6F7C] leading-relaxed'>{props.text}</p>
          <a onClick={toggle} className='uppercase flex justify-start items-start text-xs font-DMSans mt-4 cursor-pointer'><div className='my-auto w-[2rem] border-pink-red border mr-2 '></div>En savoir plus</a>
        </div>
        <div className='w-[21.9375rem] h-[13.5rem] relative lg:w-[33.4375rem] lg:h-[22rem] '>
            <div className='bg-pink-red h-full w-1/2 absolute right-0'></div>
            <img className='w-[20.4375rem] h-[12.5rem] z-1 absolute top-2 right-2 lg:w-[32rem] lg:h-[21rem]' src={props.img}></img>
        </div>
      </div>
      )
    }
  }  
}
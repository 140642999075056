import React from 'react'

export default function Faq(props) {
  return (
    <div className='flex flex-col justify-center items-center px-4 md:px-8 lg:px-40 mt-12'>
        <h2 className=' text-[1.875rem] text-[#E51337] font-Poppins font-bold lg:w-[36rem] lg:h-[2.25rem] mx-auto mt-8'>Frequently Asked Questions [FAQ]</h2>
        <p className='font-DMSans text-[#78716C] font-normal  mx-6 mt-4'>Les questions les plus fréquentes</p>
        <div className='w-full grid grid-cols-1 mt-8'>
            {props.children}
        </div>
    </div>
  )
}

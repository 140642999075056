import { Carousel } from "flowbite-react";
import UseScreenWidth from "../hooks/UseScreenWidth.js";
export default function Caroussel(props) {
  const screenWidth = UseScreenWidth();
  const customTheme={
    "indicators": {
      "active": {
        "off": "bg-slate-200 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-slate-400",
        "on": "bg-black dark:bg-gray-400"
      },
      "base": "h-1 w-1 rounded-full",
      "wrapper": "absolute bottom-[-1rem] left-1/2 flex -translate-x-1/2 space-x-3"
    }
  }
  if(screenWidth < 768){
    return (
      <div className="w-[20rem] h-[18.75rem] mt-12 mb-12">
            <Carousel
            theme={customTheme}
            leftControl={<img src='left.svg' className="absolute bottom-[-2rem] left-[-0.25rem]"></img>}
            rightControl={<img src='right.svg' className="absolute bottom-[-2rem] right-[-0.25rem]"></img>}
            >
              <div className="">
              <img src={props.img1} className="h-[12.375rem] w-[20.81rem]" alt="..." />
              <p className="font-DMSans font-semibold leading[1.5rem] mt-4 text-left tracking-wider uppercase">{props.p1}</p>
              </div>
              <div className="">
              <img src={props.img2} className="h-[12.375rem] w-[20.81rem]"  alt="..." />
              <p className="font-DMSans font-semibold leading[1.5rem] mt-4 text-left tracking-wider uppercase">{props.p2}</p>
              </div>
              <div className="">
              <img src={props.img3} className="h-[12.375rem] w-[20.81rem]" alt="..." />
              <p className="font-DMSans font-semibold leading[1.5rem] mt-4 text-left tracking-wider uppercase">{props.p3}</p>
              </div>
            </Carousel>
      </div>
        );
  }else{
    return(
    <div className="w-full h-[15rem] flex flex-col justify-center items-center mt-8 lg:w-[80rem] lg:mt-32 lg:h-fit lg:px-20 ">
      <p className="w-[22rem] mx-auto text-[#2a2a2a] font-Poppins font-normal lg:text-[1.5rem] lg:w-fit">{props.title}
      </p>
      <div className="grid grid-cols-3 mt-4 lg:w-full lg:grid-cols-auto">
        <div className="   flex flex-col justify-center items-center w-[12.44rem] lg:w-full mx-4 lg:mx-0">
          <img src={props.img1} className="w-[12.44rem] h-[9.06025rem] lg:w-[20.7875rem] lg:h-[13rem]"></img>
          <p className=" text-left uppercase text-[0.5rem] tracking-wider mt-2 h-[3.470rem] lg:w-[20.7875rem] lg:text-[0.85rem] font-medium">{props.p1}</p>
        </div>
        <div className="  flex flex-col justify-center items-center w-[12.44rem] lg:w-full mx-4 lg:mx-0">
          <img src={props.img2} className="w-[12.44rem] h-[9.06025rem] lg:w-[20.7875rem] lg:h-[13rem]"></img>
          <p className=" text-left uppercase  text-[0.5rem] tracking-wider mt-2 h-[3.470rem] lg:w-[20.7875rem] lg:text-[0.85rem] font-medium">{props.p2}</p>
        </div>
        <div className="  flex flex-col justify-center items-center w-[12.44rem] lg:w-full mx-4 lg:mx-0">
          <img src={props.img3} className="w-[12.44rem] h-[9.06025rem] lg:w-[20.7875rem] lg:h-[13rem]"></img>
          <p className=" text-left uppercase  text-[0.5rem] tracking-wider mt-2 h-[3.470rem] lg:w-[20.7875rem] lg:text-[0.85rem] font-medium">{props.p3}</p>
        </div>
      </div>

    </div>)
  }
}


import React, { useState } from "react";
import { Link } from "react-router-dom";
import CommingSoon from "./CommingSoon";
import ModalForm from "./ModalForm";
export default function Footer() {
  const [popup, setPopup] = useState(false);
  const [popups, setPopups] = useState(false)
  const togglep = () => {
    setPopup(!popup);
  };
  const togglec = () =>{
    setPopups(!popups)
  }
  console.log(popups)
  return (
    <div className="bg-black mt-16 pl-4 md:pl-12 text-white flex flex-col justify-start items-start md:mt-32 lg:pb-8">
      <CommingSoon popup={popup} toggle={togglep} />
      <ModalForm popups={popups} toggle={togglec}/>
      <p className="w-[13.5rem] h-[3.5rem] mt-10 text-left font-Poppins tracking-[0.2875rem] lg:w-[39.25rem] lg:text-[2rem]">
        Rejoignez <span className="text-pink-red">B Partner</span>
      </p>
      <button
        className="mt-4 bg-white text-black rounded-sm p-2 font-DMSans md:w-[20.5rem] lg:w-[12.0625rem]"
        onClick={togglep}
      >
        Ouvrir un compte
      </button>
      <div className="lg:flex lg:flex-col">
        <div className="lg:flex lg:flex-row lg:order-2 lg:w-full lg:relative">
          <Link to="/">
            <img
              className="w-[4.625rem] h-[3.6875rem] md:w-[9.45969rem] md:h-[3.6875rem] mt-10 lg:mt-0"
              alt="logo"
              src="logo.svg"
            ></img>
          </Link>
          <div className="flex flex-col lg:flex-row lg:justify-center lg:items-center lg:absolute lg:h-full lg:right-8">
            <a
              href="#"
              className="w-[4.625rem] h-[1.8125] lg:w-[8rem] lg:text-center text-left  text-[#CCC] font-semibold mt-6 lg:my-auto"
            >
              Home
            </a>
            <a
              href="mLegaleBpartner.pdf"
              target="_blank"
              className="w-[8rem] h-[1.8125] lg:w-[8rem] lg:text-center  text-left text-[#CCC] font-semibold mt-6 lg:my-auto"
            >
              Mentions légales
            </a>
            <a
              href="cgu.pdf"
              target="_blank"
              className="w-[8rem] h-[1.8125] lg:w-[8rem] lg:text-center  text-left text-[#CCC] font-semibold mt-6 lg:my-auto"
            >
              CGU
            </a>
            <a
              href="confidentialite.pdf"
              target="_blank"
              className="w-[8rem] h-[1.8125] lg:w-[8rem] lg:text-center  text-left text-[#CCC] font-semibold mt-6 lg:my-auto"
            >
              Politique de confidentialité
            </a>
            <div
              className="w-[4.625rem] h-[1.8125] lg:w-[8rem] lg:text-center  text-left text-[#CCC] font-semibold mt-6 lg:my-auto cursor-pointer"
              onClick={togglec}
            >
              Contact
            </div>
          </div>
        </div>
        <p className="text-left mt-10 mb-10 w-[20rem] md:w-[42rem] lg:w-full lg:pr-8 font-DMSans lg:order-1">
          © B Partner 2024 - ISO 14001, ISO 27001, ISO 9001
          <br />
          <br />B Partner SRL est une société immatriculée au registre de
          commerce en Belgique, sous le numéro BE 1003965341. Siège social : 161
          Drève Richelle, 1410 Waterloo, Belgique. Téléphone +33 1 82 83 06 80,
          Site web : <a href="www.b-partner.com">www.b-partner.com</a>
        </p>
        <p className="text-left w-[20rem] mb-10 md:w-[42rem] lg:w-full lg:pr-8 font-DMSans lg:order-1">
          Le compte B Partner Premium, Premium Business, Prestige ou Prestige
          Business, est un compte portefeuille de monnaie électronique aussi
          appelé "e-money wallet"
        </p>
        <p className="text-left mb-10 w-[20rem] md:w-[42rem] lg:w-full lg:pr-8 font-DMSans lg:order-1">
          La Carte est émise par Paynovate SA en vertu d’une licence de Visa
          International Inc. Paynovate SA est autorisée par la Banque National
          de Belgique à émettre de la monnaie électronique et à fournir les
          services de paiement associés (réf 0506.763.929), dans le cadre de la
          réglementation applicable aux établissements de Monnaie Électronique.
          Paynovate SA est un membre principal de Visa.
        </p>
      </div>
         
    </div>
  );
}

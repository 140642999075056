import React from 'react'
import HubspotForm from 'react-hubspot-form'

export default function ContactForm() {
return (
    <div className='px-8 mt-8'>
        <HubspotForm
            region="eu1"
            portalId="144530178"
            formId="716d2df6-e542-4fc4-ae35-9f0c5bb49750"
        />
    </div>
    );
}

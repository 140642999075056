import React from 'react'
import ComparatifMobile from './ComparatifMobile.js';
import ComparatifDekstop from "./ComparatifDesktop.js";
import useScreenWidth from '../hooks/UseScreenWidth.js';
import Footer from '../components/Footer.js';
export default function Comparatif() {
  const screenWidth = useScreenWidth();
  if(screenWidth < 1024){
    return(
      <div>
      <ComparatifMobile />
      <Footer />
      </div>
    )
  }
    return(
      <div>
      <ComparatifDekstop />
      <Footer />
      </div>
    )
}

import React,{Children, useState, useEffect} from 'react'

export default function ComparatifAccordeonDesktop(props) {
    const [dropped, setDropped] =useState(props.open)
    const drop = ()=>{
        setDropped(!dropped);
    }

    useEffect(() => {
      setDropped(props.open);
    }, [props.open]);

  return (
    <div className={`flex flex-col custom-shadow mt-8 lg:mx-[4.5rem]`}>
        <div onClick={drop} className='flex flex-row cursor-pointer h-[3.2556rem] items-center px-2'>
            <p className='w-full text-left font-Poppins text-[1.125rem] font-semibold uppercase text-pink-red'>{props.title}</p>
            {dropped ? <img className='w-[1.5rem] h-[1.5rem]' src='group-less.png' alt='arrow'/> : <img className='w-[1.5rem] h-[1.5rem]' src='group-plus.png' alt='arrow'/>}
        </div>
        <div className={` ${!dropped ? 'hidden': ''} flex flex-col px-2`}>
          {props.children}
        </div>
    </div>
  )
}

import React, { useState } from 'react'

export default function ComparatifAccordeon(props) {
  const [dropped, setDropped] = useState(props.dropped)

  const drop = () => {
    setDropped(!dropped);
  }

  const renderResult = (result) => {
    if (result === 'img') {
      return <img className='w-[1.5rem] h-[1.5rem] my-auto ml-2' src='greenValid.png' alt='green valid'/>
    } else if (result === 'croix') {
      return <img className='w-[1.5rem] h-[1.5rem] my-auto ml-2' src='croix.png' alt='red croix'/>
    } else {
      return <p className='font-Poppins text-[0.875rem] ml-2 text-right'>{result}</p>
    }
  }

  return (
    <div className='flex flex-col mt-6 custom-shadow'>
      <div onClick={drop} className='flex flex-row h-[3.5625rem] items-center px-2'>
        <p className='w-full text-left font-Poppins text-[1.125rem] font-semibold '>{props.title}</p>
        {dropped ? <img src='group-less.png' alt='arrow'/> : <img src='group-plus.png' alt='arrow'/>}
      </div>
      <div className={`${!dropped ? 'hidden' : ''} flex flex-col mb-4 px-2`}>
        {/* ligne 1 */}
        <div className='flex flex-row justify-between mt-2'>
          <div className='flex flex-col'>
            <p className='text-left font-Poppins text-[0.875rem]'>{props.lign1}</p>
            <p className={`${props.sublign1 === '' ? 'hidden' : ''} text-left text-[#98949E] font-Poppins text-[0.875rem]`}>{props.sublign1}</p>
          </div>
          {renderResult(props.result1)}
        </div>
        {/* ligne 2 */}
        <div className={`flex flex-row justify-between mt-2 ${props.lign2 === undefined ? 'hidden' : ''}`}>
          <div className='flex flex-col'>
            <p className='text-left font-Poppins text-[0.875rem]'>{props.lign2}</p>
            <p className={`${props.sublign2 === '' ? 'hidden' : ''} text-left text-[#98949E] font-Poppins text-[0.875rem]`}>{props.sublign2}</p>
          </div>
          {renderResult(props.result2)}
        </div>
        {/* ligne 3 */}
        <div className={`flex flex-row justify-between mt-2 ${props.lign3 === undefined ? 'hidden' : ''}`}>
          <div className='flex flex-col'>
            <p className='text-left font-Poppins text-[0.875rem]'>{props.lign3}</p>
            <p className={`${props.sublign3 === '' ? 'hidden' : ''} text-left text-[#98949E] font-Poppins text-[0.875rem]`}>{props.sublign3}</p>
          </div>
          {renderResult(props.result3)}
        </div>
        {/* ligne 4 */}
        <div className={`flex flex-row justify-between mt-2 ${props.lign4 === undefined ? 'hidden' : ''}`}>
          <div className='flex flex-col'>
            <p className='text-left font-Poppins text-[0.875rem]'>{props.lign4}</p>
            <p className={`${props.sublign4 === '' ? 'hidden' : ''} text-left text-[#98949E] font-Poppins text-[0.875rem]`}>{props.sublign4}</p>
          </div>
          {renderResult(props.result4)}
        </div>
        {/* ligne 5 */}
        <div className={`flex flex-row justify-between mt-2 ${props.lign5 === undefined ? 'hidden' : ''}`}>
          <div className='flex flex-col'>
            <p className='text-left font-Poppins text-[0.875rem]'>{props.lign5}</p>
            <p className={`${props.sublign5 === '' ? 'hidden' : ''} text-left text-[#98949E] font-Poppins text-[0.875rem]`}>{props.sublign5}</p>
          </div>
          {renderResult(props.result5)}
        </div>
        {/* ligne 6 */}
        <div className={`flex flex-row justify-between mt-2 ${props.lign6 === undefined ? 'hidden' : ''}`}>
          <div className='flex flex-col'>
            <p className='text-left font-Poppins text-[0.875rem]'>{props.lign6}</p>
            <p className={`${props.sublign6 === '' ? 'hidden' : ''} text-left text-[#98949E] font-Poppins text-[0.875rem]`}>{props.sublign6}</p>
          </div>
          {renderResult(props.result6)}
        </div>
        {/* ligne 7 */}
        <div className={`flex flex-row justify-between mt-2 ${props.lign7 === undefined ? 'hidden' : ''}`}>
          <div className='flex flex-col'>
            <p className='text-left font-Poppins text-[0.875rem]'>{props.lign7}</p>
            <p className={`${props.sublign7 === '' ? 'hidden' : ''} text-left text-[#98949E] font-Poppins text-[0.875rem]`}>{props.sublign7}</p>
          </div>
          {renderResult(props.result7)}
        </div>
        {/* ligne 8 */}
        <div className={`flex flex-row justify-between mt-2 ${props.lign8 === undefined ? 'hidden' : ''}`}>
          <div className='flex flex-col'>
            <p className='text-left font-Poppins text-[0.875rem]'>{props.lign8}</p>
            <p className={`${props.sublign8 === '' ? 'hidden' : ''} text-left text-[#98949E] font-Poppins text-[0.875rem]`}>{props.sublign8}</p>
          </div>
          {renderResult(props.result8)}
        </div>
        {/* ligne 9 */}
        <div className={`flex flex-row justify-between mt-2 ${props.lign9 === undefined ? 'hidden' : ''}`}>
          <div className='flex flex-col'>
            <p className='text-left font-Poppins text-[0.875rem]'>{props.lign9}</p>
            <p className={`${props.sublign9 === '' ? 'hidden' : ''} text-left text-[#98949E] font-Poppins text-[0.875rem]`}>{props.sublign9}</p>
          </div>
          {renderResult(props.result9)}
        </div>
        {/* ligne 10 */}
        <div className={`flex flex-row justify-between mt-2 ${props.lign10 === undefined ? 'hidden' : ''}`}>
          <div className='flex flex-col'>
            <p className='text-left font-Poppins text-[0.875rem]'>{props.lign10}</p>
            <p className={`${props.sublign10 === '' ? 'hidden' : ''} text-left text-[#98949E] font-Poppins text-[0.875rem]`}>{props.sublign10}</p>
          </div>
          {renderResult(props.result10)}
        </div>
        {/* ligne 11 */}
        <div className={`flex flex-row justify-between mt-2 ${props.lign11 === undefined ? 'hidden' : ''}`}>
          <div className='flex flex-col'>
            <p className='text-left font-Poppins text-[0.875rem]'>{props.lign11}</p>
            <p className={`${props.sublign11 === '' ? 'hidden' : ''} text-left text-[#98949E] font-Poppins text-[0.875rem]`}>{props.sublign11}</p>
          </div>
          {renderResult(props.result11)}
        </div>
      </div>
    </div>
  )
}
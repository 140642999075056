import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import TopBar from '../components/TopBar'
import JoinUsBtn from '../components/JoinUsBtn'
import Caroussel from '../components/Caroussel'
import PrestigeCard from '../components/PrestigeCard'
import Value from '../components/Value'
import CardAdvantage from '../components/CardAdvantage'
import Footer from '../components/Footer'
import CommingSoon from '../components/CommingSoon';
export default function Landing() {
    const [popup, setPopup] = useState(false);
    const toggle = ()=>{
        setPopup(!popup);
    }
  return (
<div className='flex flex-col'>
    <TopBar />
    {/* div video */}
    <div className='relative'>
    <video
        className='object-cover w-full h-screen'
        loop
        muted ={true}
        autoPlay={true}
        playsInline
        controls={false}
      >
        <source src={'/video2.mp4'} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-Poppins md:w-[43.72rem] lg:w-full lg:flex lg:flex-col lg:justify-center lg:items-center'>
        <h1 className='text-4xl w-80 leading-[3.5rem] font-normal tracking-wider font-Poppins md:w-full md:border-l-[3px] border-white md:ml-4 md:text-left md:pl-4 md:text-[3rem] md:mt-[19rem] lg:w-[45.18rem] lg:translate-x-16 lg:translate-y-14'>
        Un Engagement Sans Faille, Vers <span className='text-pink-red'>L’Excellence</span>
        </h1>
        <p className='font-Poppins tracking-wider mt-8 md:mt-[17rem] md:text-left md:text-[1.5rem] md:p-4 md:w-[39rem] lg:self-start lg:ml-[5rem] lg:-translate-y-16 lg:w-[45rem]'>
        <span className='md:text-[2rem]'>Un compte multi-devise sans <span className='md:text-pink-red'> {' '}frontieres<br className='hidden md:flex'/></span></span> 
        {' '} accompagné de services innovants pour faciliter votre gestion financière.
        </p>
      </div>
    </div>
    <CommingSoon popup={popup} toggle={toggle}/>
    {/* reste page */}
    <div className='bg-white px-5 flex flex-col items-center mt-[2rem] lg:px-14'>
    {/* detail offres */}
        <p className='font-Poppins lg:text-[1.75rem] font-medium mt-8 lg:w-[62rem] mx-auto mb-12 text-[1.5rem]  lg:mb-0'>Chez B Partner, vos fonds ne sont pas réinvestis. Ils restent donc <span className='text-pink-red '>disponibles à tout moment</span> pour nos clients.</p>
    <div className='flex flex-col items-center lg:flex-row lg:w-[80.4375rem] lg:mt-12 lg:justify-around lg:items-center'>
        <div className='md:w-[48rem] md:h-[36.5rem] md:flex md:flex-row md:justify-center md:items-center md:px-[2rem] md:mt-4 lg:w-[48rem] lg:h-full lg:mt-12 lg:px-0 lg:translate-y-6'>
            {/* card 1 text */}
            <div className='w-[21.375rem] pl-1 md:w-[23rem] md:h-[27.5rem] md:z-10 md:-translate-y-6 lg:w-[20rem] lg:h-[25.5rem]'>
                <div className='w-40 h-fit bg-pink-red p-[0.5rem] md:h-[27.375rem] md:w-[11.25rem] lg:w-[10.5625rem] lg:h-[26.5rem]'>
                    <div className='w-80 bg-bp-grey h-[23.75rem] flex flex-col justify-center items-center md:h-[26.375rem] md:w-[22.0625rem] lg:w-[20.6875rem] lg:h-[25.5rem]'>
                        <div className=' w-[12rem] pl-2 lg:w-[15rem]'>
                            <h3 className='text-[1rem] lg:text-[1.25rem] text-white font-Poppins tracking-[3px] font-semibold  h-[2rem] text-left'>
                                Particuliers
                            </h3>
                            <p className='text-white font-Poppins text-[0.5rem] lg:text-[0.75rem] tracking-[3px] leading-[1rem] text-left'>
                            Une offre unique, personnalisée et à votre image.
                            </p>
                        </div>

                        <div className=' w-[12rem] mt-[1rem] pl-2 lg:w-[15rem] lg:mt-8'>
                            <h3 className='text-[1rem] lg:text-[1.25rem] text-white font-Poppins tracking-[3px] font-semibold  h-[2rem] text-left'>
                                Profesionnels
                            </h3>
                            <p className='text-white font-Poppins text-[0.5rem] lg:text-[0.75rem] tracking-[3px] leading-[1rem] text-left'>
                            Des solutions flexibles pour faciliter votre gestion
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* card 1 img */}
            <div className='w-[21rem] px-1 mt-[1rem] md:w-[27.75rem] md:h-[33.5rem] lg:w-[25.1875rem] lg:h-full lg:-translate-x-16'>
                <img className="lg:-translate-y-8" src="img-card-1.png" alt="Card 1" />
            </div>
        </div>
        {/* div nos offres */}
        <div className='w-[20.5rem] px-1 mt-[1rem] flex flex-col md:w-[38.125rem] md:-translate-x-8 justify-start md:mt-0 lg:w-[25rem] lg:pr-10 lg:-translate-x-0'>
            <p className='w-[10rem] h-[3.5rem] flex items-center font-Poppins tracking-[0.2875rem] md:w-[12.125] md:[h-2.5rem] lg:w-[14rem]   uppercase'>Nos Offres
            </p>
            <p className='text-[1.5rem] lg:text-[2rem] font-normal tracking-[0.2875rem] text-left leading-[3rem] border-l-[3px] pb-4 border-black pl-[1rem]'>
            Souscrivez à une Offre Adaptée à Vos Besoins
            </p>
            <p className='w-[20.9375rem] mt-[1rem]  font-DMSans text-left p-0 md:w-full '>
            L'excellence et la personnalisation au cœur de nos services. Vivez l'expérience d'une gestion financière fluide, efficace, simple et transparente            </p>
            <p className='w-[20.9375rem] mt-[1.5rem] mb-[1.5rem] font-DMSans text-left  p-0 md:w-full lg:mb-10'>
            Chez B Partner, nous comprenons les rythmes irréguliers et les besoins fluctuants de nos clients d'exception.            </p>
            <div className='w-fit' onClick={toggle}><JoinUsBtn text='REJOIGNEZ NOUS'/></div>
        </div>
    </div>
            <Caroussel 
            title="NOS SERVICES COMPLEMENTAIRES"
            img1='img-crsl-1.jpeg'
            img2='bourse.png'
            img3='img-crls-2.jpeg'
            p1="Facilitez-vous la vie avec notre service de conciergerie"
            p2="Protégez votre réputation en ligne grâce à notre service E-réputation"
            p3="Soyez récompensé de votre fidélité grâce à notre programme de cash-back"/>
        {/* div nos offres */}
    <div className='lg:flex lg:flex-row lg:mt-24 lg:w-[80rem] lg:px-16 lg:justify-around '>
        <div className='w-[20.5rem] px-1 mt-[1rem] flex flex-col md:w-[38.125rem] md:-translate-x-8 md:mt-12 justify-start md:ml-8 lg:w-[26.625rem] md:hidden lg:flex lg:justify-center'>
            <p className='w-[11rem] h-[3.5rem] flex items-center font-Poppins tracking-[0.2875rem] md:w-[12.125] md:[h-2.5rem]  lg:text-xl lg:w-[14rem]'>Notre gamme
            </p>
            <p className='text-[1.5rem] lg:text-[2rem] font-normal tracking-[0.2875rem] text-left leading-[3rem] border-l-[3px] pb-4 border-black pl-[1rem]'>
            Découvrez nos Offres Adaptées à vos Besoins
            </p>
            <p className='w-[20.9375rem] mt-[1rem]  font-DMSans text-left p-0 md:w-full md:mb-6 lg:text-[1.125rem] mb-6 '>
            Chez B Partner, nos gammes sont conçues sur mesure pour répondre aux personnalités exigentes et exceptionnelles de nos clients
            </p>
            <div className='w-fit' onClick={toggle}><JoinUsBtn onClick={toggle} text='REJOIGNEZ NOUS'/></div>
        </div>
        {/* cards */}
        <div className='md:mt-12 md:flex md:flex-row '>
            <Link to='/prestige'>
            <PrestigeCard 
                img='img-card-1.png'
                offset={true} 
                title='PRESTIGE' 
                subtitle='Un Compte à la hauteur de votre personnalité' 
                lign1='Services Prestige' 
                lign2='Assistant Personnel'
                lign3='Service de E-Reputation'
                link='#'/>
            </Link>
            <Link to='/premium'>
            <PrestigeCard
                img='img-card-2.png'
                offset={false} 
                title='PREMIUM' 
                subtitle='un compte qui comprend votre besoin de flexibilité.' 
                lign1='Paiements en devises' 
                lign2='Programme de fidélité'
                lign3='Service de conciergerie'
                link='#'/>
            </Link>
        </div>
    </div>
        {/* Nos offres */}
    <div className=' lg:flex lg:flex-row lg:w-[80rem] lg:px-16 lg:justify-around lg:mt-24'>
        <div className='w-[20.5rem] px-1 mt-[1rem] flex flex-col md:w-[38.125rem] md:-translate-x-8 md:mt-12 justify-start md:ml-8 lg:w-[26.625rem] lg:justify-center lg:mr-16'>
            <p className='w-[10rem] h-[3.5rem] flex items-center font-Poppins tracking-[0.2875rem] md:w-[12.125] md:[h-2.5rem] uppercase lg:text-xl lg:w-[14rem]'>NOS VALEURS
            </p>
            <p className='text-[1.5rem] lg:text-[2rem] font-normal tracking-[0.2875rem] text-left leading-[3rem] border-l-[3px] pb-4 border-black pl-[1rem]'>
            B Partner, l'excellence dans la finance, redéfinie 
            </p>
            <p className='w-[20.9375rem] mt-[1rem]  font-DMSans text-left p-0 md:w-full md:mb-6 lg:text-[1.125rem] mb-6'>
            L'Excellence et la qualité sont nos mots d'ordre quand il s'agit de vous aider à gérer vos finances. 
            </p>
            <p className='w-[20.9375rem]  font-DMSans text-left p-0 md:w-full md:mb-6 lg:text-[1.125rem] mb-6'>
            Nos certifications ISO renforcent nos capacités et soutiennent vos projets tout en sécurisant vos données 
            </p>
            <div className='w-fit' onClick={toggle}><JoinUsBtn onClick={toggle} text='REJOIGNEZ NOUS'/></div>
        </div>
        {/* Valeurs */}
        <div className='md:grid md:grid-cols-3 md:w-full md:mt-4 lg:w-[55%]'>
            <Value
                number='01'
                title='Securité'
                text='Conçue pour un monde en évolution.'            
            />
            <Value
                number='02'
                title='Proximité'
                text='Dans vos usages du quotidien'            
            />
            <Value
                number='03'
                title='Tranquillité'
                text="Pour gérer vos paiements et finances"            
            />
            <Value
                number='04'
                title='Confidentialité'
                text='Et protection sans concession'            
            />
            <Value
                number='05'
                title='Personnalisation'
                text='Des services et produits'            
            />
            <Value
                number='06'
                title='Exclusivité'
                text='Des solutions uniques, et inédites'            
            />
        </div>
    </div>
        {/* Detail Offres */}
    <div className=' lg:flex lg:flex-row lg:w-[80rem] md:mt-24 lg:justify-between lg:items-center lg:px-16 hidden lg:hidden'>
        <div className='md:w-[48rem] md:h-[36.5rem] md:flex md:flex-row md:justify-center md:items-center md:px-[2rem] md:mt-4 lg:w-[64rem] lg:h-full lg:mt-0 lg:px-0 order-2'>
            {/* card 1 text */}
            <div className='w-[21.375rem] pl-1 mt-12 md:order-2 md:flex md:flex-row md:justify-end md:w-[23rem] md:-translate-y-16 lg:-translate-y-0 md:h-[27.5rem] lg:w-[20rem] lg:h-[25.5rem] z-10'>
                <div className='w-40 h-[24.75rem] bg-pink-red p-[0.5rem] md:h-[27.375rem] md:w-[11.25rem] lg:w-[10.5625rem] lg:h-[26.5rem]'>
                    <div className='w-80 bg-bp-grey h-[23.75rem] flex flex-col justify-center items-center md:-translate-x-[12rem] md:h-[26.375rem] md:w-[22.0625rem] lg:w-[20.6875rem] lg:h-[25.5rem] lg:-translate-x-[11.225rem]'>
                        <div className=' w-[12rem] pl-2 lg:w-[13rem]'>
                            <h3 className='text-[1rem] text-white font-Poppins tracking-[0.2875rem] font-semibold  h-[2rem] text-left lg:w-[15.5rem]'>
                            Assurance
                            </h3>
                            <p className='text-white font-Poppins text-[0.5rem] tracking-[0.2875rem] leading-[1rem] text-left'>
                            Protégez-vous des imprévus
                            </p>
                        </div>

                        <div className=' w-[12rem] pl-2 lg:w-[13rem] lg:mt-8'>
                            <h3 className='text-[1rem] text-white font-Poppins tracking-[0.2875rem] font-semibold  h-[2rem] text-left lg:w-[15.5rem]'>
                            Sans Risques
                            </h3>
                            <p className='text-white font-Poppins text-[0.5rem] tracking-[0.2875rem] leading-[1rem] text-left'>
                            Une gestion sécurisée, raffinée
                            </p>
                        </div>

                        <div className=' w-[12rem] pl-2 lg:w-[13rem] lg:mt-8'>
                            <h3 className='text-[1rem] text-white font-Poppins tracking-[0.2875rem] font-semibold  h-[2rem] text-left lg:w-[15.5rem]'>
                            Confiance
                            </h3>
                            <p className='text-white font-Poppins text-[0.5rem] tracking-[0.2875rem] leading-[1rem] text-left'>
                            Votre sérénité, notre priorité
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-[21rem] px-1 mt-[1rem] md:w-[27.75rem] md:h-[36.5rem] lg:w-[25.1875rem] lg:h-full'>
                <img className="lg:translate-x-12" src="img-card-1.png" alt="Card 1" />
            </div>
        </div>
        {/* Nos offres */}
        <div className='w-[20.5rem] mt-[1rem] flex flex-col md:w-[38.125rem] lg:-translate-x-16 md:ml-16 justify-start lg:w-[34rem] lg:pr-8 order-1 hidden'>
            <p className='w-[14rem] h-[3.5rem] flex items-center font-Poppins tracking-[0.2875rem] md:w-[14rem] md:[h-2.5rem] lg:w-[16rem] lg:mb-6 uppercase'>Benefices
            </p>
            <p className='text-[1.5rem] lg:text-[2rem] font-normal tracking-[0.2875rem] text-left leading-[3rem] border-l-[3px] pb-4 border-black pl-[1rem] '>
            Soyez Assurés à tout instant
            </p>
            <p className='w-[20.9375rem] mt-[1rem]  font-DMSans text-left p-0 md:w-full '>
            L'excellence et la personnalisation au cœur de nos services. Vivez l'expérience d'une gestion financière fluide et élégante.
            </p>
            <p className='w-[20.9375rem] mt-[1.5rem] mb-[1.5rem] font-DMSans text-left  p-0 md:w-full lg:mb-10 '>
            Chez B Partner, nous comprenons les rythmes irréguliers et les besoins fluctuants de nos clients d'exception.
            </p>
            <div className='w-fit' onClick={toggle}><JoinUsBtn onClick={toggle} text='REJOIGNEZ NOUS'/></div>
        </div>
    </div>
    </div>
    
    <Footer />
</div>
  )
}

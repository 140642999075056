import React from 'react'
import HubspotForm from 'react-hubspot-form'
export default function ModalForm(props) {
  console.log(props.popups)
    if(props.popups == true){
        return (
          <div id='popup' className='fixed top-0 left-0 w-full h-full z-50 bg-[#56565686] flex justify-center items-center'>
            <div className='w-full h-full lg:w-fit lg:h-fit bg-white opacity-100 relative flex flex-col justify-center items-center'>
              <img onClick={props.toggle} src="red-close.svg" alt='close' className='absolute w-6 lg:w-8 lg:h-8 top-4 right-4 cursor-pointer'/>
              <img className='w-24 h-auto mr-auto ml-10 lg:mt-8 lg:ml-24' src='white-logo.jpg'/>
              <div className='mt-4 lg:w-full lg:px-24 lg:py-6 lg:flex lg:justify-center lg:items-center lg:mx-auto'>
                <HubspotForm
                    region="eu1"
                    portalId="144530178"
                    formId="716d2df6-e542-4fc4-ae35-9f0c5bb49750"
                />
              </div>
            </div>
          </div>
        )
    }else{
        <></>
    }
}

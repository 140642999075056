import React,{useState} from 'react'
import ComparatifAccordeon from './ComparatifAccordeon'
import Footer from './Footer.js'
import CommingSoon from './CommingSoon.js';
export default function CompMobContent(props) {
    const [popup, setPopup] = useState(false);
    const toggle = ()=>{
        setPopup(!popup);
    }
  if(props.cible === 'particuliers' && props.offre === 'prestige'){
    return(
    <div>
        <CommingSoon popup={popup} toggle={toggle}/>
        <div className='flex flex-col w-full px-4'>
            <div className='w-[12.125rem] h-[8.0625rem] mx-auto mt-8'>
              <div className='pr-[0.4rem] py-[0.4rem] gradient-container'>
                <img className='image' src='carte-dupont.png'></img>
              </div>
            </div>
            <button onClick={toggle} className='h-[2rem] w-[11.6875rem] bg-pink-red text-white font-DMSans font-bold mx-auto mt-6'>
                Choisir
            </button>
            <p className='font-Poppins font-semibold mt-6'>39,00€/Mois</p>
            <h2 className='w-full text-left text-[1.125rem] font-Poppins font-semibold mt-6'>Offre <span className='text-pink-red'>PRESTIGE</span> pour particuliers</h2>
            <p className='text-left font-Poppins text-[0.625rem] mt-2 leading-[1.125rem]'>
            Cette offre exclusive s’adresse à des clients exigeants, qui seront ravis découvrir une suite de services d’exception telle qu’une conciergerie, aucune limite de paiement, ou encore un programme de fidélité.
            </p>
            <h2 className='w-full text-left text-[1.125rem] font-Poppins font-semibold mt-6'>Plus en détail</h2>
            <p className='font-Poppins font-normal text-left'>Vous trouverez ci-dessous les services et fonctionnalités rattachés à cette offre.</p>
            <ComparatifAccordeon dropped={true} title='Comptes et opérations' 
                lign1='Compte en euros' sublign1='' result1='img'
                lign2='Paiement sortant en euros (SEPA)' sublign2='' result2='img'
                lign3='Paiement instantanné en euros (SEPA)' sublign3='' result3='img'
                lign4='Paiement programmés et récurrents' sublign4='' result4='img'
                lign5='Paiement en devise' sublign5='' result5='Plus de 30 devises'
            />
            <ComparatifAccordeon dropped={false} title='Services à valeur ajoutée' 
                lign1='Programme fidélité B Partner' sublign1='' result1='img'
                lign2='Conciergerie' sublign2='' result2='img'
                lign3='Dépannage cash à l’étranger' sublign4='' result3='2%'
            />
            <ComparatifAccordeon dropped={false} title='Services prestiges' 
                lign1='Assistant personnel' sublign1='' result1='img'
                lign2='Conseiller financier personnel' sublign2='' result2='img'
                lign3='Bilan e-reputation annuel' sublign3='' result3='img'
            />
            <ComparatifAccordeon dropped={false} title='Paiement au quotidien' 
                lign1='Paiement en zone euro gratuits' sublign1='' result1='img'
                lign2='Carte virtuelle gratuite' sublign2='' result2='Illimité'
                lign3='Plafonds de paiement' sublign3='' result3='Gestion en ligne'
                lign4='Carte de débit VISA internationale' sublign4='' result4='Métal'
                lign5='Paiements hors zone euro' sublign5='' result5='img'
            />
            <ComparatifAccordeon dropped={false} title='Retraits et services' 
                lign1='Cash-back pour tous les paiements' sublign1='' result1='Jusqu’à 1,25%'
                lign2='Retraits en zone France' sublign2='' result2='5 gratuits'
            />
            <p className='text-left pl-6 mt-8 font-DMSans text-[1.125rem]'>Pour en savoir plus, vous pouvez télécharger nos Conditions Tarifaires <a className='text-pink-red underline cursor-pointer' href='conditionTarifParticuliers.pdf' target='_blank'>ici</a></p>
        </div>
    </div>
    )
  }else if(props.cible === 'particuliers' && props.offre === 'premium'){
    return(
        <div>
        <CommingSoon popup={popup} toggle={toggle}/>
        <div className='flex flex-col w-full px-4'>
            <div className='w-[12.125rem] h-[8.0625rem] mx-auto mt-8'>
              <div className='pr-[0.4rem] py-[0.4rem] gradient-container'>
                <img className='image' src='carte-dupont-premium.png'></img>
              </div>
            </div>
            <button onClick={toggle} className='h-[2rem] w-[11.6875rem] bg-pink-red text-white font-DMSans font-bold mx-auto mt-6'>
                Choisir
            </button>
            <p className='font-Poppins font-semibold mt-6'>18,00€/Mois</p>
            <h2 className='w-full text-left text-[1.125rem] font-Poppins font-semibold mt-6'>Offre <span className='text-pink-red'>PREMIUM</span> pour particuliers</h2>
            <p className='text-left font-Poppins text-[0.625rem] mt-2 leading-[1.125rem]'>
            Cette offre exclusive s’adresse à des clients exigeants, qui seront ravis découvrir une suite de services d’exception telle qu’une conciergerie, aucune limite de paiement, ou encore un programme de fidélité.
            </p>
            <h2 className='w-full text-left text-[1.125rem] font-Poppins font-semibold mt-6'>Plus en détail</h2>
            <p className='font-Poppins font-normal text-left'>Vous trouverez ci-dessous les services et fonctionnalités rattachés à cette offre.</p>
            <ComparatifAccordeon dropped={true} title='Comptes et opérations' 
                lign1='Compte en euros' sublign1='' result1='img'
                lign2='Paiement sortant en euros (SEPA)' sublign2='' result2='img'
                lign3='Paiement instantanné en euros (SEPA)' sublign3='' result3='img'
                lign4='Paiement programmés et récurrents' sublign4='' result4='img'
                lign5='Paiement en devise' sublign5='' result5='Plus de 30 devises'
            />
            <ComparatifAccordeon dropped={false} title='Services à valeur ajoutée' 
                lign1='Programme fidélité B Partner' sublign1='' result1='img'
                lign2='Conciergerie' sublign2='' result2='img'
                lign3='Dépannage cash à l’étranger' sublign4='' result3='2%'
            />
            <ComparatifAccordeon dropped={false} title='Services prestiges' 
                lign1='Assistant personnel' sublign1='' result1='croix'
                lign2='Conseiller financier personnel' sublign2='' result2='croix'
                lign3='Bilan e-reputation annuel' sublign3='' result3='croix'
            />
            <ComparatifAccordeon dropped={false} title='Paiement au quotidien' 
                lign1='Paiement en zone euro gratuits' sublign1='' result1='img'
                lign2='Carte virtuelle gratuite' sublign2='' result2='5 cartes offertes'
                lign3='Plafonds de paiement' sublign3='' result3='Gestion en ligne'
                lign4='Carte de débit VISA internationale' sublign4='' result4='PVC (recyclable)'
                lign5='Paiements hors zone euro' sublign5='' result5='img'
            />
            <ComparatifAccordeon dropped={false} title='Retraits et services' 
                lign1='Cash-back pour tous les paiements' sublign1='' result1='Jusqu’à 0.75%'
                lign2='Retraits en zone France' sublign2='' result2='3 gratuits'
            />
            <p className='text-left pl-6 mt-8 font-DMSans text-[1.125rem]'>Pour en savoir plus, vous pouvez télécharger nos Conditions Tarifaires <a className='text-pink-red underline cursor-pointer' href='conditionTarifParticuliers.pdf' target='_blank'>ici</a></p>
        </div>
    </div>
    )
  }else if(props.cible === 'professionnels' && props.offre === 'prestige'){
    return(
        <div>
        <CommingSoon popup={popup} toggle={toggle}/>
        <div className='flex flex-col w-full px-4'>
            <div className='w-[12.125rem] h-[8.0625rem] mx-auto mt-8'>
              <div className='pr-[0.4rem] py-[0.4rem] gradient-container'>
                <img className='image' src='carte-dupont.png'></img>
              </div>
            </div>
            <button onClick={toggle} className='h-[2rem] w-[11.6875rem] bg-pink-red text-white font-DMSans font-bold mx-auto mt-6'>
                Choisir
            </button>
            <p className='font-Poppins font-semibold mt-6'>79,00€/Mois</p>
            <h2 className='w-full text-left text-[1.125rem] font-Poppins font-semibold mt-6'>Offre <span className='text-pink-red'>PRESTIGE</span> pour professionnels</h2>
            <p className='text-left font-Poppins text-[0.625rem] mt-2 leading-[1.125rem]'>
            Cette offre exclusive s’adresse à des clients exigeants, qui seront ravis découvrir une suite de services d’exception telle qu’une conciergerie, aucune limite de paiement, ou encore un programme de fidélité.
            </p>
            <h2 className='w-full text-left text-[1.125rem] font-Poppins font-semibold mt-6'>Plus en détail</h2>
            <p className='font-Poppins font-normal text-left'>Vous trouverez ci-dessous les services et fonctionnalités rattachés à cette offre.</p>
            <ComparatifAccordeon dropped={true} title='Comptes et opérations' 
                lign1='Compte en euros' sublign1='' result1='img'
                lign2='Paiement sortant en euros (SEPA)' sublign2='' result2='img'
                lign3='Paiement instantanné en euros (SEPA)' sublign3='' result3='img'
                lign4='Paiement programmés et récurrents' sublign4='' result4='img'
                lign5='Paiement en devise' sublign5='' result5='Plus de 30 devises'
            />
            <ComparatifAccordeon dropped={false} title='Services à valeur ajoutée' 
                lign1='Programme fidélité B Partner' sublign1='' result1='img'
                lign2='Conciergerie' sublign2='' result2='img'
                lign3='Dépannage cash à l’étranger' sublign4='' result3='2%'
            />
            <ComparatifAccordeon dropped={false} title='Services prestiges' 
                lign1='Assistant personnel' sublign1='' result1='img'
                lign2='Conseiller financier personnel' sublign2='' result2='img'
                lign3='Bilan e-reputation annuel' sublign3='' result3='img'
            />
            <ComparatifAccordeon dropped={false} title='Paiement au quotidien' 
                lign1='Paiement en zone euro gratuits' sublign1='' result1='img'
                lign2='Carte virtuelle gratuite' sublign2='' result2='Illimité'
                lign3='Plafonds de paiement' sublign3='' result3='Gestion en ligne'
                lign4='Carte de débit VISA internationale' sublign4='' result4='Métal'
                lign5='Paiements hors zone euro' sublign5='' result5='img'
            />
            <ComparatifAccordeon dropped={false} title='Retraits et services' 
                lign1='Cash-back pour tous les paiements' sublign1='' result1='Jusqu’à 1,5%'
                lign2='Retraits en zone France' sublign2='' result2='10 gratuits'
            />
            <ComparatifAccordeon dropped={false} title='Services pour professionnels' 
                lign1='Terminaux de paiements m-POS' sublign1='' result1='Sur demande'
                lign2='Outils d’analyse' sublign2='' result2='Fin 2024'
                lign3='Emission de factures' sublign3='' result3='Fin 2024'
                lign4='Lien de paiement par SMS ou Email' sublign4='' result4='Fin 2024'
            />
        </div>
        <p className='text-left pl-6 mt-8 font-DMSans text-[1.125rem]'>Pour en savoir plus, vous pouvez télécharger nos Conditions Tarifaires <a className='text-pink-red underline cursor-pointer' href='conditionTarifParticuliers.pdf' target='_blank'>ici</a></p>
    </div>
    )
  }else{
    return(
        <div>
        <CommingSoon popup={popup} toggle={toggle}/>
        <div className='flex flex-col w-full px-4'>
            <div className='w-[12.125rem] h-[8.0625rem] mx-auto mt-8'>
              <div className='pr-[0.4rem] py-[0.4rem] gradient-container'>
                <img className='image' src='carte-dupont-premium.png'></img>
              </div>
            </div>
            <button onClick={toggle} className='h-[2rem] w-[11.6875rem] bg-pink-red text-white font-DMSans font-bold mx-auto mt-6'>
                Choisir
            </button>
            <p className='font-Poppins font-semibold mt-6'>49,00€/Mois</p>
            <h2 className='w-full text-left text-[1.125rem] font-Poppins font-semibold mt-6'>Offre <span className='text-pink-red'>PREMIUM</span> pour professionnels</h2>
            <p className='text-left font-Poppins text-[0.625rem] mt-2 leading-[1.125rem]'>
            Cette offre exclusive s’adresse à des clients exigeants, qui seront ravis découvrir une suite de services d’exception telle qu’une conciergerie, aucune limite de paiement, ou encore un programme de fidélité.
            </p>
            <h2 className='w-full text-left text-[1.125rem] font-Poppins font-semibold mt-6'>Plus en détail</h2>
            <p className='font-Poppins font-normal text-left'>Vous trouverez ci-dessous les services et fonctionnalités rattachés à cette offre.</p>
            <ComparatifAccordeon dropped={true} title='Comptes et opérations' 
                lign1='Compte en euros' sublign1='' result1='img'
                lign2='Paiement sortant en euros (SEPA)' sublign2='' result2='img'
                lign3='Paiement instantanné en euros (SEPA)' sublign3='' result3='img'
                lign4='Paiement programmés et récurrents' sublign4='' result4='img'
                lign5='Paiement en devise' sublign5='' result5='Plus de 30 devises'
            />
            <ComparatifAccordeon dropped={false} title='Services à valeur ajoutée' 
                lign1='Programme fidélité B Partner' sublign1='' result1='img'
                lign2='Conciergerie' sublign2='' result2='img'
                lign3='Dépannage cash à l’étranger' sublign3='' result3='2%'
            />
            <ComparatifAccordeon dropped={false} title='Services prestiges' 
                lign1='Assistant personnel' sublign1='' result1='croix'
                lign2='Conseiller financier personnel' sublign2='' result2='croix'
                lign3='Bilan e-reputation annuel' sublign3='' result3='croix'
            />
            <ComparatifAccordeon dropped={false} title='Paiement au quotidien' 
                lign1='Paiement en zone euro gratuits' sublign1='' result1='img'
                lign2='Carte virtuelle gratuite' sublign2='' result2='10 cartes offertes'
                lign3='Plafonds de paiement' sublign3='' result3='Gestion en ligne'
                lign4='Carte de débit VISA internationale' sublign4='' result4='PVC (recyclable)'
                lign5='Paiements hors zone euro' sublign5='' result5='img'
            />
            <ComparatifAccordeon dropped={false} title='Retraits et services' 
                lign1='Cash-back pour tous les paiements' sublign1='' result1='Jusqu’à 1%'
                lign2='Retraits en zone France' sublign2='' result2='5 gratuits'
            />
            <ComparatifAccordeon dropped={false} title='Services pour professionnels' 
                lign1='Terminaux de paiements m-POS' sublign1='' result1='Sur demande'
                lign2='Outils d’analyse' sublign2='' result2='Fin 2024'
                lign3='Emission de factures' sublign3='' result3='Fin 2024'
                lign4='Lien de paiement par SMS ou Email' sublign4='' result4='Fin 2024'
            />
        </div>
        <p className='text-left pl-6 mt-8 font-DMSans text-[1.125rem]'>Pour en savoir plus, vous pouvez télécharger nos Conditions Tarifaires <a className='text-pink-red underline cursor-pointer' href='conditionTarifParticuliers.pdf' target='_blank'>ici</a></p>
    </div>
    )
  }
}

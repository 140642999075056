import React from 'react'

export default function LignAccDesk(props) {
  return (
    <div className={`flex border-b mt-2 flex-row w-full ${props.colored === true ? 'bg-[#D9D9D94D]':''} justify-end my-1 items-center`}>
      <div className='flex flex-col text-left mr-auto font-Poppins text-[0.90rem]'>
        <p className={`flex items-center ${props.bold === undefined ? '' : 'text-[1.125rem] font-semibold'}`}>{props.title}</p>
        {props.subtitle === '' ? '' : 
        <p className='text-[#98949E]'>{props.subtitle}</p>}
      </div>
      <div className='h-fit w-[18rem] flex justify-center mr-[3.8rem]'>
        {props.prestige === 'img' ? 
          <img src='greenValid.png' alt='valid arrow' className='w-[1.5rem] h-[1.5rem]'/> :
          props.prestige === 'croix' ? 
          <img src='croix.png' alt='croix' className='w-[1.25rem] h-[1.25rem]'/> :
          <p className='font-Poppins text-[0.90rem]'>{props.prestige}</p>
        }
      </div>
      <div className='h-fit w-[18rem] flex justify-center mr-[2rem]'>
        {props.premium === 'img' ? 
          <img src='greenValid.png' alt='valid arrow' className='w-[1.5rem] h-[1.5rem]'/> :
          props.premium === 'croix' ? 
          <img src='croix.png' alt='croix' className='w-[1.25rem] h-[1.25rem]'/> :
          <p className='font-Poppins text-[0.90rem]'>{props.premium}</p>
        }
      </div>
    </div>
  )
}
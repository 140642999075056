import React from 'react'

export default function Value(props) {
  return (
    <div className='flex flex-row md:flex-col mt-[2rem] md:w-[12.18rem] md:m-auto md:mt-12 lg:h-[12rem]'>
        <div className='w-[4.625rem] h-[4rem] flex justify-start items-start text-[2.5rem] font-Poppins'>{props.number}</div>

        <div className='flex flex-col text-left mt-2 ml-4 md:mt-0 md:ml-0'>
            <div className='font-Poppins font-semibold tracking-widest'>
                {props.title}
            </div>
            <div className='font-DMSans font-medium tracking-widest w-[12.8rem] md:mt-2 lg:w-fit '>
                {props.text}
            </div>
        </div>
    </div>
  )
}
